<template>
  <div class="map-modal relative">
    <div class="map-modal-container">
      <div class="map-modal-header">
        <div class="map-modal-header-text-container">
          <span class="map-modal-header-text">{{ txt }}</span>
        </div>
      </div>
      <div v-if="mapContent && track === 'nordtrack'" class="map-modal-content">
        <Map :content="mapContent" :current="currentLocationIndex" />
      </div>
      <div v-if="mapContent && track === 'lokotrack'" class="map-modal-content">
        <MapLokotrack :content="mapContent" :current="currentLocationIndex" />
      </div>
      <div class="map-modal-footer">
        <span
          v-if="currentLocationIndex !== -1"
          class="map-modal-footer-title"
          >{{ mapContent.map[currentLocationIndex].text }}</span
        >
      </div>
    </div>
    <!-- close button -->
    <div
      class="modal-close-x inline-block absolute top-0 right-0 cursor-pointer"
    >
      <ModalsModalUiModalCloseButton class="py-4 px-4" @click="toggleMap" />
    </div>
  </div>
</template>

<script setup>
const track = useState("track").value;
const { settingsData } = useSettings();
const settings = settingsData.value;

const txt = settings.global_texts[0].Text;

const route = useRoute();
const currentLocation = route.fullPath;

const map = useMap();
const toggleMap = () => {
  map.value = !map.value;
};

let mapContent = ref(null);

const currentLocationIndex = computed(() => {
  let index = -1;
  if (mapContent.value) {
    index = mapContent.value.map.findIndex((item) => {
      return currentLocation.includes(item.link.cached_url);
    });
  }
  return index;
});

onMounted(() => {
  mapContent.value = { map: [] };
  if (settings.main_navigation) {
    settings.main_navigation.forEach((navi) => {
      if (navi.link.id !== "") {
        mapContent.value.map.push(navi);
      }
    });
  }
  if (settings.products_list) {
    settings.products_list.forEach((list) => {
      if (list.products) {
        list.products.forEach((navi) => {
          mapContent.value.map.push(navi);
        });
      }
      if (list.link) {
        mapContent.value.map.push(list);
      }
    });
  }
  if (settings.map_navigation) {
    settings.map_navigation.forEach((navi) => {
      mapContent.value.map.push(navi);
    });
  }
});
</script>

<style lang="scss">
.map-modal {
  z-index: 2000;
  position: absolute;
  right: 20px;
  bottom: 82px;
  width: 440px;
}

.map-modal-container {
  background: white;
}

.map-modal-header {
  display: flex;
  flex-direction: row;

  &-empty {
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
    align-items: center;
    flex: 1;
  }

  &-text-container {
    display: flex;
    margin-top: 1rem;
    align-items: center;
    flex: 1;
    text-align: center;
    justify-content: center;
  }

  &-text {
    font-size: 20px;
  }
}

.map-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

.map-modal-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 20px 34px 20px;

  &-title {
    font-size: 1.5rem;
  }

  &-text {
    margin-top: 1rem;
    font-size: 1rem;
  }
}

.close {
  margin-top: 1rem;
  margin-right: 1rem;
  display: flex;
  flex: 1;
  justify-content: flex-end;

  button {
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    text-decoration: none;
    outline: none;
  }
}

@media only screen and (max-width: 768px) {
  .map-modal-container {
    transform: scale(0.8);
    width: 100vw;
    height: 100vh;
    margin-top: 1em;

    .map-modal {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
    }
  }
}
</style>
